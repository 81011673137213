import { gql } from '@apollo/client';

export const RESTAURANT_OVERVIEW_QUERY = gql`
  query getRestaurantOverview($subsidiaryId: String, $postalCode: String) {
    getRestaurants(subsidiaryId: $subsidiaryId, postalCode: $postalCode) {
      restaurants {
        name
        address {
          fax
          phone
          email
          postalCode
          town
          streetname
          streetnumber
        }
        restType
        openingHoursSchedule {
          regularOpeningDays {
            closingTime {
              formattedHour
            }
            openingTime {
              formattedHour
            }
            closed
            code
            weekDay
            formattedDate
          }
        }
        geoPoint {
          latitude
          longitude
        }
        type
        distanceFromPostalCode
        code
        seoData {
          url
        }
        urlCode
      }
    }
  }
`;
