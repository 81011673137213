import { gql } from '@apollo/client';

export const STORE_OVERVIEW_QUERY = gql`
  query getStoreOverview($subsidiaryId: String, $showAll: Boolean, $postalCode: String) {
    getPointOfServices(subsidiaryId: $subsidiaryId, showAll: $showAll, postalCode: $postalCode) {
      pointOfServices {
        seoData {
          title
          description
          noIndex
          noFollow
          url
        }
        address {
          fax
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        regularOpeningHours
        distanceFromPostalCode
        bigPicture {
          altText
          url
        }
        code
        geoPoint {
          latitude
          longitude
        }
        longDescription
        name
        newFlag
        openingHoursText
        services {
          code
          name
          icon {
            altText
            url
          }
        }
        type
      }
    }
  }
`;
