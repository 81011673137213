import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import loadable from '@loadable/component';

import useCurrentPosition from 'modules/libs/geo-service/useCurrentPosition';
import StoreInfo from 'pages/StoreOverview/components/StoreInfo';
import { isArrayEmpty, noop } from '@xxxlgroup/hydra-utils/common';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import { Breakpoint } from 'components/MediaQuery/MediaQuery.types';
import useMessage from 'components/Message/useMessage';
import LocationFinder from 'components/LocationFinder';
import SkipSection from 'components/SkipSection';
import {
  LocationSourcesFromStorage,
  OnSubmitZipCode,
} from 'components/LocationFinder/LocationFinder.types';
import { RefType } from 'components/AnchorBar/AnchorBar.types';
import { RESTAURANT, STORE, WAREHOUSE } from 'pages/StoreOverview/StoreOverview';
import { LocationData, StoveOverviewData } from 'pages/StoreOverview/StoreOverview.types';
import storeInfoStyle from 'pages/StoreOverview/components/StoreInfo/StoreInfo.scss';
import MobileControlsToggle from 'pages/StoreOverview/components/StoreOverviewMap/components/MobileControlsToggle';
import TypeFilter from 'pages/StoreOverview/components/StoreOverviewMap/components/TypeFilter';
import { StoreTypes } from 'pages/StoreOverview/components/StoreOverviewMap/components/TypeFilter/TypeFilter';

import styles from 'pages/StoreOverview/components/StoreOverviewMap/StoreOverviewMap.scss';

interface StoreOverviewMapProps {
  /** Array of filters for store type */
  filterTypeArray?: StoreTypes[];
  /** Flag to mark kitchen showroom map */
  isKitchenShowroomMap?: boolean;
  /** store list */
  storeList?: Array<StoveOverviewData>;
  /** function to set filter by store type */
  setFilterTypeArray?: Dispatch<SetStateAction<Array<StoreTypes>>>;
  /** function to set user Postal code */
  setPostalCode: Dispatch<SetStateAction<string>>;
  /** flag to show or hide type filters */
  shouldShowTypeFilters?: boolean;
  /** types of the store */
  storeTypes?: StoreTypes[];
  /** user postal code */
  userPostalCode?: string;
}

const MapLegend = loadable(() => import('modules/store-service/components/MapLegend'), {
  ssr: false,
});

const StoreMap = loadable(
  () => import('pages/StoreOverview/components/StoreOverviewMap/components/StoreMap'),
  { ssr: false },
);

export const DEFAULT_ZOOM = 7;
const FOCUSED_ZOOM = 14;

const StoreOverviewMap = ({
  filterTypeArray = [STORE, WAREHOUSE, RESTAURANT],
  isKitchenShowroomMap = false,
  storeList = [],
  setFilterTypeArray = noop,
  setPostalCode,
  shouldShowTypeFilters = false,
  storeTypes,
  userPostalCode = '',
}: StoreOverviewMapProps) => {
  const listRef = useRef<RefType>(null);
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<StoveOverviewData | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<LocationData | null>(null);
  const [isOverlayShown, setIsOverlayShown] = useState(false);
  const [isListVisible, setIsListVisible] = useState(true);
  const [locationsTranslation] = useMessage(['poseidon.subsidiary.location.label']);

  const isDesktopView = useMediaQuery({ largerThan: Breakpoint.lg });
  const userGeo = useCurrentPosition();
  const userCoords = userGeo?.position?.coords;

  const defaultCenter = useMemo(() => {
    if (isArrayEmpty(storeList)) {
      return undefined;
    }
    const coordinates = {
      latitude:
        storeList.reduce((sum, store) => sum + (store.geoPoint?.latitude || 0), 0) /
        storeList.length,
      longitude:
        storeList.reduce((sum, store) => sum + (store.geoPoint?.longitude || 0), 0) /
        storeList.length,
    };
    return { lat: coordinates?.latitude, lng: coordinates?.longitude, zoom: DEFAULT_ZOOM };
  }, [storeList]);

  useEffect(() => {
    const activeStore = listRef.current?.querySelector<HTMLElement>(
      `.${storeInfoStyle.activeStore}`,
    );
    activeStore && listRef.current && (listRef.current.scrollTop = activeStore.offsetTop);
  }, [storeList, selectedSubsidiary]);

  const handleSelectStore = useCallback(
    (store: StoveOverviewData) => () => {
      const { latitude, longitude } = store.geoPoint || {};
      setSelectedSubsidiary(store);
      latitude &&
        longitude &&
        setSelectedLocation({
          lat: latitude,
          lng: longitude,
          zoom: FOCUSED_ZOOM,
        });
      setIsOverlayShown(true);
    },
    [setSelectedSubsidiary, setSelectedLocation, setIsOverlayShown],
  );

  useEffect(() => {
    if (userPostalCode && !isArrayEmpty(storeList)) {
      handleSelectStore(storeList[0])();
    }
  }, [userPostalCode, storeList, handleSelectStore]);

  const handleZipInputChange = useCallback(
    (zipCodeData: string) => {
      if (zipCodeData === '') {
        setPostalCode('');
        setSelectedLocation(null);
        setSelectedSubsidiary(null);
      }
    },
    [setPostalCode, setSelectedLocation],
  );

  const handleZipSubmit: OnSubmitZipCode = useCallback(
    ({ zipCode, setAriaLiveMessage }) => {
      setPostalCode(zipCode);
      setAriaLiveMessage?.('locationFinder.aria.live.only.available.subsidiaries', {
        availableCount: storeList?.length,
      });
    },
    [setPostalCode, storeList?.length],
  );

  const changeView = (showListFlag: boolean) => () => {
    setIsListVisible(showListFlag);
  };

  const closeOverlay = () => {
    setIsOverlayShown(false);
  };

  const renderLocationsNumber = () =>
    storeList && (
      <div className={styles.storesNumber}>
        {storeList.length} {locationsTranslation}
      </div>
    );

  const renderTypeFilter = () =>
    shouldShowTypeFilters &&
    storeTypes && (
      <TypeFilter
        filterArray={filterTypeArray}
        setFilterArray={setFilterTypeArray}
        storeTypes={storeTypes}
      />
    );

  const renderLocationSearch = () => (
    <LocationFinder
      locationSourceFromStorage={listRef.current ? null : LocationSourcesFromStorage.USER_LOCATION}
      className={styles.locationSearch}
      classNameInput={styles.locationInput}
      onSubmitZipCode={handleZipSubmit}
      onChangeInput={handleZipInputChange}
    />
  );

  const renderStoreList = (stores: Array<StoveOverviewData>, testId: string) => (
    <ul className={styles.storeListItems} data-testid={testId}>
      {stores.map((store) => (
        <li key={store.code}>
          <StoreInfo
            store={store}
            onClick={handleSelectStore}
            onMapShowClick={changeView(false)}
            selectedCode={selectedSubsidiary?.code}
            isSmallView={!isDesktopView}
          />
        </li>
      ))}
    </ul>
  );

  const renderDesktopView = () => (
    <div className={styles.finder}>
      <SkipSection id="map-desktop" section="Google Map">
        <>
          {renderLocationsNumber()}
          <div className={styles.finderWrapper}>
            <div className={styles.listWrapper} data-testid="store.list.wrapper">
              {renderLocationSearch()}
              {renderTypeFilter()}
              <div className={styles.storeList} ref={listRef}>
                {renderStoreList(storeList, 'store.info')}
              </div>
            </div>
            <StoreMap
              storeList={storeList}
              userCoords={userCoords}
              selectedLocation={selectedLocation}
              closeOverlay={closeOverlay}
              defaultCenter={defaultCenter}
              handleSelectStore={handleSelectStore}
              isListVisible={isListVisible}
              isOverlayShown={isOverlayShown}
              selectedSubsidiary={selectedSubsidiary}
            />
          </div>
          <MapLegend isKitchenShowroomMap={isKitchenShowroomMap} storeTypes={storeTypes} />
        </>
      </SkipSection>
    </div>
  );

  const renderMobileView = () => (
    <div className={styles.finder} data-testid="overview.map.mobile">
      <SkipSection id="map-mobile" section="Google Map">
        <>
          {renderLocationSearch()}
          {renderTypeFilter()}
          <MobileControlsToggle isListShown={isListVisible} onChangeView={changeView} />
          {renderLocationsNumber()}
          <div
            className={classnames(styles.smallViewContent, { [styles.listVisible]: isListVisible })}
          >
            <div
              className={classnames(styles.storeList, styles.storeListSmallView, {
                [styles.storeListHidden]: !isListVisible,
              })}
              ref={listRef}
            >
              {renderStoreList(storeList, 'store.info.small')}
            </div>
            {!isListVisible && (
              <>
                <StoreMap
                  storeList={storeList}
                  userCoords={userCoords}
                  selectedLocation={selectedLocation}
                  closeOverlay={closeOverlay}
                  defaultCenter={defaultCenter}
                  handleSelectStore={handleSelectStore}
                  isListVisible={isListVisible}
                  isOverlayShown={isOverlayShown}
                  selectedSubsidiary={selectedSubsidiary}
                />
                <MapLegend isKitchenShowroomMap={isKitchenShowroomMap} storeTypes={storeTypes} />
              </>
            )}
          </div>
        </>
      </SkipSection>
    </div>
  );

  return isDesktopView ? renderDesktopView() : renderMobileView();
};

export default React.memo(StoreOverviewMap);
