import React, { useContext, useEffect, useMemo, useState } from 'react';
import CountryContext from 'Providers/IntlProvider/CountryContext';
import track from 'react-tracking';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import useRoutes from 'hooks/useRoutes';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { Breadcrumbs, BreadcrumbsItem, Heading } from '@xxxlgroup/hydra-ui-components';
import { tagComponent } from 'utils/tracking/tracking';
import BreadcrumbsHomeItem from 'components/BreadcrumbsHomeItem';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import WebshopLink from 'components/WebshopLink';
import CmsContent from 'components/CmsContent/CmsContent';
import useMessage from 'components/Message/useMessage';
import Helmet from 'components/Helmet';
import getSortedFilteredServices from 'pages/StoreOverview/utils/getSortedFilteredServices';
import StoreOverviewMap from 'pages/StoreOverview/components/StoreOverviewMap';
import styles from 'pages/StoreOverview/StoreOverview.scss';
import { STORE_OVERVIEW_QUERY } from 'pages/StoreOverview/StoreOverview.query';
import { RESTAURANT_OVERVIEW_QUERY } from 'pages/StoreOverview/RestaurantOverview.query';
import { StoveOverviewData } from 'pages/StoreOverview/StoreOverview.types';
import { StoreTypes } from 'pages/StoreOverview/components/StoreOverviewMap/components/TypeFilter/TypeFilter';

export const STORE = 'STORE';
export const WAREHOUSE = 'WAREHOUSE';
export const RESTAURANT = 'RESTAURANT';

const StoreOverviewPage = () => {
  const routes = useRoutes();
  const [storeFinderLinkText] = useMessage(['storefinder.link'], {}, true);
  const [storeFinderTitle] = useMessage(['poseidon.storeoverviewpage.title']);
  const [userPostalCode, setUserPostalCode] = useState('');
  const [filterTypeArray, setFilterTypeArray] = useState<StoreTypes[]>([
    STORE,
    WAREHOUSE,
    RESTAURANT,
  ]);
  const isRestaurantsEnabled = useFeatureFlagsEnabled(['restaurant.overview.enabled']);
  const country = useContext<any>(CountryContext);
  const baseURL = country?.shopUrl || '';
  const breadcrumbLink = routes?.storeOverview?.breadcrumbLink || '';
  const canonicalUrl = `${baseURL}${breadcrumbLink}`;

  const { pointOfServices } =
    useQuery(STORE_OVERVIEW_QUERY, {
      variables: { showAll: true },
      skip: !!userPostalCode,
    })?.data?.getPointOfServices ?? {};

  const { pointOfServices: distanceSortedPointOfServices } =
    useQuery(STORE_OVERVIEW_QUERY, {
      variables: { postalCode: userPostalCode },
      skip: !userPostalCode,
    })?.data?.getPointOfServices ?? {};

  const { restaurants } =
    useQuery(RESTAURANT_OVERVIEW_QUERY, {
      skip: !!userPostalCode || !isRestaurantsEnabled,
    })?.data?.getRestaurants ?? {};

  const { restaurants: restaurantsSorted } =
    useQuery(RESTAURANT_OVERVIEW_QUERY, {
      variables: { postalCode: userPostalCode },
      skip: !userPostalCode || !isRestaurantsEnabled,
    })?.data?.getRestaurants ?? {};

  const shouldHideTypeFilters = useMemo(() => {
    const data =
      pointOfServices?.concat(restaurants) ||
      distanceSortedPointOfServices?.concat(restaurantsSorted);
    const filteredData = data?.filter((store: StoveOverviewData) => store && store?.type !== 'POS');
    return (
      filteredData?.every((pos: StoveOverviewData) => pos.type === filteredData[0].type) ?? true
    );
  }, [pointOfServices, distanceSortedPointOfServices, restaurants, restaurantsSorted]);

  const storeList = useMemo(() => {
    const services =
      distanceSortedPointOfServices?.concat(restaurantsSorted) ||
      pointOfServices?.concat(restaurants) ||
      [];

    return getSortedFilteredServices(services, Boolean(distanceSortedPointOfServices));
  }, [distanceSortedPointOfServices, pointOfServices, restaurants, restaurantsSorted]);

  const availableStoreTypes: StoreTypes[] = useMemo(
    () => [...new Set(storeList.map((store) => store.type as StoreTypes))],
    [storeList],
  );

  const storeListWithAppliedFilters = useMemo(
    () => storeList?.filter((store) => filterTypeArray.indexOf(store.type as StoreTypes) > -1),
    [storeList, filterTypeArray],
  );

  const hasWarehouseParam = new URLSearchParams(useLocation().search).has('warehouses');

  useEffect(() => {
    setFilterTypeArray([hasWarehouseParam ? WAREHOUSE : STORE]);
  }, [hasWarehouseParam]);

  return (
    <ErrorBoundary withStatusCode>
      <Helmet canonicalUrl={canonicalUrl} title={storeFinderLinkText} />
      <Layout margin="none">
        <Breadcrumbs>
          <BreadcrumbsHomeItem />
          <WebshopLink as={BreadcrumbsItem} href={breadcrumbLink}>
            {storeFinderLinkText}
          </WebshopLink>
        </Breadcrumbs>
        <Heading className={styles.pageHeading} level={2} content={storeFinderTitle} SEO="h1" />
      </Layout>
      <StoreOverviewMap
        storeList={storeListWithAppliedFilters}
        storeTypes={availableStoreTypes}
        setPostalCode={setUserPostalCode}
        userPostalCode={userPostalCode}
        setFilterTypeArray={setFilterTypeArray}
        filterTypeArray={filterTypeArray}
        shouldShowTypeFilters={!shouldHideTypeFilters}
      />
      <CmsContent pageCode="storeoverview" contentSlot="content" hideError />
    </ErrorBoundary>
  );
};

export default track(tagComponent('StoreOverviewPage'), {
  dispatchOnMount: () => ({ event: 'pageShown' }),
})(StoreOverviewPage);
