import React, { MouseEventHandler } from 'react';
import classnames from 'classnames';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { Content, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';
import { appleMapsUrlBuilder } from 'utils/maps';
import { RESTAURANT } from 'pages/StoreOverview/StoreOverview';
import { StoveOverviewData } from 'pages/StoreOverview/StoreOverview.types';
import styles from 'pages/StoreOverview/components/StoreInfo/StoreInfo.scss';

interface StoreInfoProps {
  /** flag to determine is small view */
  isSmallView?: boolean;
  /** on click event */
  onClick?: (store: StoveOverviewData) => () => void;
  /** show map click event */
  onMapShowClick?: () => void;
  /** code of selected store */
  selectedCode?: string | null;
  /** store information */
  store: StoveOverviewData;
}

const StoreInfo = ({
  isSmallView = false,
  store,
  store: { address },
  selectedCode = null,
  onClick = () => noop,
  onMapShowClick = noop,
}: StoreInfoProps) => {
  const [
    routeText,
    showOnMapTranslation,
    storeDetailsTranslation,
    newStoreTranslation,
    restaurantTranslation,
  ] = useMessage([
    'wxs.subsidiary.calculateRoute',
    'poseidon.subsidiary.show.map.label',
    'poseidon.subsidiary.details.label',
    'poseidon.subsidiary.newstore.info',
    'poseidon.restaurant',
  ]);

  const handleClick = () => onClick(store)();
  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event?.code === 'Space') {
      event.preventDefault();
    } else if (event?.code === 'Enter') {
      onClick(store)();
    }
  };
  const handleStoreDetailsClick: MouseEventHandler = (event) => event.stopPropagation();

  const storeDistance =
    store.distanceFromPostalCode && Math.round(store.distanceFromPostalCode / 10) / 100;
  const isRestaurant = store.type === RESTAURANT;

  return (
    <div
      className={classnames(styles.store, {
        [styles.activeStore]: store.code === selectedCode,
      })}
      data-testid={store.code === selectedCode ? 'selected.store' : null}
    >
      <div onClick={handleClick} onKeyDown={handleOnKeyDown} role="button" tabIndex={0}>
        <div className={styles.storeTitle}>
          {store?.newFlag && <span className={styles.newStore}>{newStoreTranslation}</span>}
          <h4 className={styles.storeName}>
            {isRestaurant ? `${store.name} - ${restaurantTranslation}` : store.name}
          </h4>
          {Boolean(store.distanceFromPostalCode) && (
            <span className={styles.distance} data-testid="storeInfo.distance">
              ({storeDistance} km)
            </span>
          )}
        </div>
        <p
          className={styles.storeDescription}
        >{`${address?.streetname} ${address?.streetnumber}, ${address?.postalCode} ${address?.town}`}</p>
        {store.regularOpeningHours && (
          <Content className={styles.storeDescription} content={store.regularOpeningHours} />
        )}
      </div>
      <Link
        as={<HydraLink typography="xsmall" link="a" underline theme="dark" />}
        onClick={handleStoreDetailsClick}
        className={styles.storeLink}
        href={store?.seoData?.url || (isRestaurant && `/restaurant/${store.urlCode}`)}
      >
        {storeDetailsTranslation}
      </Link>
      <Link
        as={<HydraLink typography="xsmall" link="a" underline theme="dark" />}
        className={styles.storeLink}
        href={appleMapsUrlBuilder(
          address?.streetname,
          address?.streetnumber,
          address?.postalCode,
          address?.town,
        )}
        isForceSelfTarget
        target="_blank"
      >
        {routeText}
      </Link>
      {isSmallView && (
        <div className={styles.findOnMapWrapper}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <HydraLink
            className={styles.storeLink}
            data-testid="show.on.map"
            typography="xsmall"
            link="a"
            underline
            theme="tertiary"
            onClick={onMapShowClick}
          >
            {showOnMapTranslation}
          </HydraLink>
        </div>
      )}
    </div>
  );
};

export default StoreInfo;
