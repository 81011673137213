import { StoveOverviewData } from 'pages/StoreOverview/StoreOverview.types';

const createSort =
  (isSortedByDistance: boolean) => (serviceA: StoveOverviewData, serviceB: StoveOverviewData) => {
    const postalCodeDiff =
      (Number(serviceA.address?.postalCode) || 0) - (Number(serviceB.address?.postalCode) || 0);
    const distanceDiff =
      (serviceA?.distanceFromPostalCode || 0) - (serviceB?.distanceFromPostalCode || 0) ?? 0;

    const comparisonDiff = isSortedByDistance ? distanceDiff : postalCodeDiff;

    if (!comparisonDiff && serviceA.type !== serviceB.type) {
      return serviceA.type === 'WAREHOUSE' ? 1 : -1;
    }

    return comparisonDiff;
  };
const getSortedFilteredServices = (
  services?: Array<StoveOverviewData> | [],
  isSortedByDistance = false,
) => {
  if (!services?.length) {
    return [];
  }

  return [...services]
    .filter((service) => service && service?.type !== 'POS')
    .sort(createSort(isSortedByDistance));
};

export default getSortedFilteredServices;
