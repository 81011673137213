import React from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { Button } from '@xxxlgroup/hydra-ui-components';
import { map, bars } from '@xxxlgroup/hydra-icons';
import useMessage from 'components/Message/useMessage';
import styles from 'pages/StoreOverview/components/StoreOverviewMap/components/MobileControlsToggle/MobileControlsToggle.scss';

interface MobileControlsToggleProps {
  isListShown: boolean;
  onChangeView?: (showListFlag: boolean) => () => void;
}

const MobileControlsToggle = ({
  isListShown,
  onChangeView = () => noop,
}: MobileControlsToggleProps) => {
  const [mapTranslation, listTranslation] = useMessage([
    'poseidon.subsidiary.map.label',
    'poseidon.subsidiary.pos.list.label',
  ]);

  return (
    <div className={styles.viewButtons}>
      <Button
        className={styles.toggleButton}
        data-testid="show.list.button"
        variant={isListShown ? 'secondary' : 'tertiary'}
        type="button"
        onClick={onChangeView(true)}
        glyphAfter={bars}
      >
        {listTranslation}
      </Button>
      <Button
        className={styles.toggleButton}
        data-testid="show.map.button"
        variant={isListShown ? 'tertiary' : 'secondary'}
        type="button"
        onClick={onChangeView(false)}
        glyphAfter={map}
      >
        {mapTranslation}
      </Button>
    </div>
  );
};

export default React.memo(MobileControlsToggle);
