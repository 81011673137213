import React, { ChangeEvent, FC, useCallback } from 'react';
import useMessage from 'components/Message/useMessage';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { Checkbox } from '@xxxlgroup/hydra-ui-components';
import { RESTAURANT, STORE, WAREHOUSE } from 'pages/StoreOverview/StoreOverview';
import styles from 'pages/StoreOverview/components/StoreOverviewMap/components/TypeFilter/TypeFilter.scss';

const isInArray = (value: StoreTypes, array: StoreTypes[] | string[]) => array?.includes(value);

export type StoreTypes = typeof STORE | typeof WAREHOUSE | typeof RESTAURANT;

interface TypeFilterProps {
  /** Array of filters for store type */
  filterArray: StoreTypes[];
  /** Function to set filter array */
  setFilterArray: (value: StoreTypes[]) => void;
  /** Array of the available store types that can be filtered */
  storeTypes: StoreTypes[];
}

const TypeFilter: FC<TypeFilterProps> = ({ filterArray, setFilterArray, storeTypes }) => {
  const isStoreFilter = isInArray(STORE, filterArray);
  const isWarehouseFilter = isInArray(WAREHOUSE, filterArray);
  const isRestaurantFilter = isInArray(RESTAURANT, filterArray);
  const hasStores = isInArray(STORE, storeTypes);
  const hasWarehouses = isInArray(WAREHOUSE, storeTypes);
  const hasRestaurants = isInArray(RESTAURANT, storeTypes);
  const [storeTranslation, warehouseTranslation, restaurantTranslation] = useMessage([
    'wxs.form.select.subsidiary.label',
    'poseidon.subsidiary.warehouse.label',
    'poseidon.restaurant',
  ]);
  const isRestaurantsEnabled = useFeatureFlagsEnabled(['restaurant.overview.enabled']);

  const updateFilters = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const clickedFilter = event.target.value as StoreTypes;

      setFilterArray(
        !filterArray.includes(clickedFilter)
          ? [clickedFilter, ...filterArray]
          : filterArray.filter((filter) => filter !== clickedFilter),
      );
    },
    [filterArray, setFilterArray],
  );

  return (
    <div className={styles.typeFilter}>
      {hasStores && (
        <Checkbox
          className={styles.checkbox}
          checked={isStoreFilter}
          data-purpose="type.filter.store.checkbox"
          onChange={updateFilters}
          value={STORE}
        >
          {storeTranslation}
        </Checkbox>
      )}
      {hasWarehouses && (
        <Checkbox
          className={styles.checkbox}
          checked={isWarehouseFilter}
          data-purpose="type.filter.warehouse.checkbox"
          onChange={updateFilters}
          value={WAREHOUSE}
        >
          {warehouseTranslation}
        </Checkbox>
      )}
      {isRestaurantsEnabled && hasRestaurants && (
        <Checkbox
          className={styles.checkbox}
          checked={isRestaurantFilter}
          data-purpose="type.filter.restaurant.checkbox"
          onChange={updateFilters}
          value={RESTAURANT}
        >
          {restaurantTranslation}
        </Checkbox>
      )}
    </div>
  );
};

export default React.memo(TypeFilter);
